<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router} from '@inertiajs/svelte';
  import { onMount } from 'svelte';

  import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  import EventHeader from './Partials/QuickEventHeader.svelte';
  
  export let eventData = $page.props.event;
  export let standings = $page.props.standings;
  export let pageName = "Current standings";
  export let appName = $page.props.appName;

	export let hash = eventData.hash;


  
  
  // ========================================================
  // Reactive statements

  $: if(false) {
      // console.log(`text`);
  }


  // ======================================================
  onMount(() => {
    // console.log($page.props);
    
  });
</script>
<svelte:head>
    <title>{pageName} > { appName }.</title>
</svelte:head>












<div class="h-full">
  <EventHeader active="standings" title="QuickEvents" />
    <ViewHeader>{pageName}</ViewHeader>
    <div class=" mt-1 pt-4 md:pt-6 pb-16">

      <table class="table table-xs sm:table-sm md:table-md lg:table-lg max-w-3xl mx-auto">
        <thead>
          <tr>
            <th class="max-w-12">Rank</th>
            <th></th>
            <th>Player name</th>
            <th>Games <span class="hidden md:inline">played</span></th>
            <th>Pts/game</th>
            <th>Bonus</th>
            <th>Total <span class="hidden md:inline">points</span></th>
          </tr>
        </thead>
        <tbody>
          {#each standings as row, i (row.lid)}
          <tr class={`hover ${(row.inactive) ? 'text-neutral' : ''} `}>
            <td>
              <span class="font-bold">{i+1}</span> 
            </td>
            <td>
              {#if row.inactive }
              <span class="cursor-help" title="Player is paused and not counted towards final standings">⏸️</span>
              {/if}      
            </td>
            
            <td class="text-nowrap">{row.name}</td>
            <td class="text-center">{row.games}</td>
            <td>{row.average.toFixed(2)}</td>
            <td>{row.wins}</td>
            <td class="font-bold text-center">{row.total}</td>
          </tr>
          {:else}
          <tr>
            <td colspan="7" class="text-center text-info py-4">Standings are not available yet</td>
          </tr>
          {/each}
        </tbody>
      </table>  
  
  </div>
</div>