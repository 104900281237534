<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
  import { onMount } from 'svelte';


  import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  import FormSection from 'cmpt/app_ui/FormSection.svelte';
  import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';


	export let pageName = 'Record match score';
  export let appName = $page.props.appName;


  //shortening variable references in code below
  export let teamA = $page.props.teamA;
  export let teamB = $page.props.teamB;
  export const targetScore = $page.props.scoreLimit || 21; // The score that wins the match.



  // ========================================================
  let form = useForm({
    session: undefined,
    lid: undefined,
    event: undefined,
    scoreA: "",
    scoreB: "",
    errorA: false,
    errorB: false,
    winner: "0",
    status: "normal"
  });


  // function checks score values on input change and provide adequate formatting 

  function checkScore() {

    //auto complete winning score for B team
    if($form.scoreA < targetScore && $form.scoreA !== "" && $form.scoreB == "") {
        $form.scoreB = targetScore;
        $form.winner = "2"; // for type consistency with forms
        $form.errorB = $form.errorA = false;

      }

    // auto complete winning score for A team  
    if($form.scoreB < targetScore  && $form.scoreB !== "" && $form.scoreA == "") {
      $form.scoreA = targetScore;
      $form.winner = "1"; // for type consistency with forms
      $form.errorB = $form.errorA = false;

    }

    // Error checking if both values are set
    if ($form.scoreA !== "" && $form.scoreB !== "") {

      // set everything to normal
      $form.errorB = $form.errorA = false;


      // equal scores entered => unclear winner warning
      if($form.scoreA === $form.scoreB) {
        $form.winner = "0";
        $form.errorB = $form.errorA = true;
      }

      // none of scores correspond to point limit => unclear score warning
      if($form.scoreA != targetScore && $form.scoreB != targetScore) {
        $form.errorB = $form.errorA = true;
        $form.winner = "0";
      }

      //if a value is 21, then set winner back to where it needs to be
      if($form.scoreA == targetScore && $form.scoreB < targetScore) $form.winner = "1";
      if($form.scoreB == targetScore && $form.scoreA < targetScore) $form.winner = "2";

    }

  // console.log(`App name loaded: ${$page.props.appName}`);
  // console.log(`(${$form.scoreA}) : (${$form.scoreB}), Winner: ${$form.winner}`);
  // console.log(`A: ${$form.errorA ? 'error' : 'ok'} / B:${$form.errorB ? 'error' : 'ok'}`);
    // console.log($form);

  }



  // ========================================================
  // Flash error messages on form sending
  function flashErrors() {
    console.log("Errors while saving:", $form.errors)
  }


  // ========================================================
  // Send the score to server
  function sendScore() {

    console.log(route("pub.qe.home", $page.props.event));
    $form.post(route("event.match.setscore"),{
      onStart: () => console.log("Sending score..."),
      onFinish: () => {
        console.log("sending completed");
        router.visit(route('pub.qe.home', $page.props.event));
      },
      onError: () => flashErrors()
    });
  }


  // ========================================================
  // Reactive statements

  $: if($form) {
      
    }

  // ensure non-negative inputs
  $:  if($form.scoreA < 0) $form.scoreA = 0;
  $:  if($form.scoreB < 0) $form.scoreB = 0;



  onMount(() => {
    $form.lid = $page.props.lid;
    $form.session = $page.props.sessionHash;
    $form.event = $page.props.event;
    console.log($page.props);
    // console.log($page.props);
    
  });

    // ======================================================
</script>
<svelte:head>
    <title>{pageName} > { appName }.</title>
</svelte:head>



<div class="h-full">
  <ViewHeader>
    {pageName}
  </ViewHeader>

    <p class="text-secondary text-center max-w-lg mx-auto">Match #{$page.props.matchNumber} on court "{$page.props.court}"</p>

    <div class="max-w-md mx-auto my-8">
      <h4 class="text-secondary font-bold">Final score</h4>
      <table class="table border rounded-md">
        <tr class="bg-base-200">
          <th>Players</th>
          <th>Score</th>
        </tr>
        <tr>
          <td class="w-full {`${$form.winner == 1 ? 'font-bold text-base' : ''}`}">{teamA}</td>
          <td>
            <input
              type="number" 
              min="0" max="21"
              class="input input-bordered w-24 text-xl {`${$form.errorA ? 'input-error' : ''} ${$form.winner == 1 ? 'font-bold' : ''}`}"
              bind:value={$form.scoreA} on:input={checkScore} />
          </td>
        </tr>
        <tr>
          <td class="{`${$form.winner == 2 ? 'font-bold text-base' : ''}`}">{teamB}</td>
          <td>
            <input
              type="number" min="0" max="21"
              class="input input-bordered w-24 text-xl {`${$form.errorB ? 'input-error' : ''} ${$form.winner == 2 ? 'font-bold' : ''}`}"
              bind:value={$form.scoreB} on:input={checkScore} />
          </td>
        </tr>
      </table>


      <h4 class="mt-8 text-secondary font-bold">Winner</h4>
      {#if $form.errorA || $form.erorB }
      <div class="badge badge-outline badge-warning">It seems score is entered in unusual format.</div>
      <div class="form-control rounded-lg">
        <label class="label cursor-pointer pl-4 ">
          <span class="label-text">not defined</span>
          <input type="radio" name="winner" class="radio" bind:group={$form.winner} value="0" />
        </label>
      </div>
      <div class="form-control">
        <label class=" rounded-lg label cursor-pointer  pl-4 {`${$form.winner == "1" ? 'font-bold bg-primary/10' : ''}`}">
          <span class="label-text">{teamA}</span>
          <input type="radio" name="winner" class="radio radio-primary" bind:group={$form.winner} value="1" />
        </label>
      </div>
      <div class="form-control">
        <label class="rounded-lg label cursor-pointer pl-4 {`${$form.winner == "2" ? 'font-bold bg-secondary/15' : ''}`}">
          <span class="label-text">{teamB}</span>
          <input type="radio" name="winner" class="radio radio-secondary" bind:group={$form.winner} value="2" />
        </label>
      </div>


      <div class="form-control mt-8">
        <div class="label">
          <span class="label-text text-secondary font-bold">Match ended as:</span>
        </div>
        <select bind:value={$form.status} class="select select-bordered w-full max-w-md">
          <option selected value="normal">Normal</option>
          <option value="walkover">Walkover</option>
          <option value="retired">Retirement / Injury</option>
          <option value="nomatch">Cancelled</option>
        </select>
      </div>
      {:else}
      <p>
        {#if $form.winner == 1} {teamA} {/if}
        {#if $form.winner == 2} {teamB} {/if}
      </p>
      {/if}

      <button class="mt-6 block btn btn-primary btn-wide mx-auto text-lg" on:click={sendScore}>Save</button>
      <button class="mt-2 block btn btn-info btn-outline mx-auto text-lg" on:click={() => window.history.back()}>Cancel</button>


    </div>

      
</div>