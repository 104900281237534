<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';
  export const layout = [SiteLayout];

</script>

<script>
    import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    import dayjs from 'dayjs';
    import relativeTime from 'dayjs/plugin/relativeTime';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    import isToday from 'dayjs/plugin/isToday'
    dayjs.extend(customParseFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(isToday);

    import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
    import DialogModal from 'cmpt/app_ui/DialogModal.svelte';
    import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';
    import FutureFeature from 'cmpt/app_ui/FutureFeature.svelte';
    import FormSection from 'cmpt/app_ui/FormSection.svelte';
    import AddPlayersForm from './Partials/AddPlayersForm.svelte';
    
    
    export let pageName = $page.props.pageTitle ?? 'Start new instant event';
    export let appName = $page.props.appName;
    export let playerNamesList = [];
    export let formDefaults = $page.props.fillData;
    export let startsToday = false;
    export let timeLabel = '';
    export let timeLabel2 = '';
    
    let nextId = 1; //this will change

    const form = useForm({
        name: '',
        description: '',
        date_starts: dayjs(formDefaults.date_starts).format('DD.MM.YY') ?? '',
        date_ends: '',
        courts: formDefaults.courts ?? 1,
        sessions: 1,
        playerNames: [],
        playerCount: formDefaults.courts * 6 ?? 7,
        isOrdered: false,
        scoreLimit: formDefaults.scoreLimit || 21,
        type: formDefaults.type ?? 0

    });

    const sendForm = () => {
        // console.log("Form send...")
        // console.log(playerNamesList);

        const names = playerNamesList.map(row => row.name).filter(name => name && name.trim()!== "");

        $form.playerNames = names;

        console.log($form.playerNames);

        $form.post(route('event.quickstart'), {
            errorBag: 'quickstart',
            preserveScroll: true,
            onSuccess: () => { console.log("event created successfully");},
            onError: () => {
                console.log("errors on event quickstart:")
                console.log($form.errors)
            },
        });
    };


    // ===============================================
    onMount(() => {
        console.log("Create event view loaded:")
        console.log($form);

        
        
        //initialize player names (for dev purpose)
        const defaultNamesList = [
    "Alex", "Benjamin", "Charlotte", "Daniel", "Emily", "Frank", "Grace", "Henry", "Isabella",
    "Jacob", "Kevin", "Lily", "Mia", "Nathan", "Olivia",
    "Parker", "Quinn", "Robert", "Sophia", "Thomas", "Ursula", "Vincent", "William",
    "Xavier", "Yasmin", "Zoe"];
        if(playerNamesList.length == 0) {
            let limit = $form.playerCount;
            for(let i=1; i<=limit; i++) {
              playerNamesList.push({id: nextId++, name: '', uniq: false, empty: true});

              //here it fills in default name from list. Comment out next line to leave fields empty
              //playerNamesList[playerNamesList.length-1].name = defaultNamesList[i-1]
            }
        }
    });



  // ========================================================
  // Reactive statements

    $: if($form.date_starts) {
        //console.log(`date changed: ${$form.date_starts}`)
        startsToday = dayjs($form.date_starts, 'DD.MM.YY').isToday();
        if(startsToday === true) {
            timeLabel = "starts today";
        } else {
            timeLabel = "starts " + dayjs().to(dayjs($form.date_starts,'DD.MM.YY'));
        }
        
    } else {
        timeLabel = '';
    }

    
</script>

<svelte:head>
    <title>{pageName} > { appName }</title>
</svelte:head>


<div class="h-full">
    <ViewHeader>
            {pageName}
    </ViewHeader>

    <div>
        <div class="max-w-6xl mx-auto py-10 sm:px-6 lg:px-8 flex flex-col border-opacity-50">
            <!-- Start of formsection -->
            <FormSection>
                <span slot="title">
                       General information
                </span>
                <span slot="description">
                        The minimum required informatoin about your event
                </span>

                <!-- Start form fields -->
                <svelte:fragment slot="formFields">
                    <label class="form-control w-full max-w-xl">
                      <div class="label">
                        <span class="label-text">Event title</span>
                      </div>
                      <input type="text" placeholder="" class="input input-bordered w-full" bind:value={$form.name} />
                      <div class="label">
                        {#if ($form.errors.name)}
                        <span class="label-text-alt text-error">{$form.errors.name}</span>{/if}
                      </div>
                    </label>

                    <label class="form-control w-full max-w-xl">
                      <div class="label">
                        <span class="label-text">Description</span>
                      </div>
                      <textarea class="textarea textarea-bordered h-24 w-full" placeholder="Event information" bind:value={$form.description}></textarea>
                      <div class="label">
                        {#if ($form.errors.description)}
                        <span class="label-text-alt text-error">{$form.errors.description}</span>{/if}
                      </div>
                    </label>

                    <label class="form-control w-full max-w-xl">
                      <div class="label">
                        <span class="label-text">Event date</span>
                      </div>
                      <input type="text" placeholder="" class="input input-bordered w-full" bind:value={$form.date_starts} />
                      <div class="label">
                        <span class="label-text-alt">{timeLabel}</span>
                        {#if ($form.errors.date_starts)}
                        <span class="label-text-alt text-error">{$form.errors.date_starts}</span>{/if}
                      </div>
                    </label>


                </svelte:fragment>
                <!-- End of form fields -->

                <!-- Start buttons and their action bindings -->
                <!-- <svelte:fragment slot="actions">
                    {#if $form.recentlySuccessful }
                    <ActionMessage style="success">
                            Profile updated
                    </ActionMessage>
                    {/if}
                    <button class="btn btn-primary text-white" on:click|preventDefault={sendForm} disabled={$form.processing}>Create event
                    </button>
                </svelte:fragment> -->
                <!-- End action buttons -->

            </FormSection>
            <!-- End of general formsection -->

        <div class="divider"></div>

            <!-- Start of settings formsection -->
            <FormSection>
                <span slot="title">
                       Event setup
                </span>
                <span slot="description">
                        The practical settings that are required to create the playing for this event
                </span>

                <!-- Start form fields -->
                <svelte:fragment slot="formFields">
                    <label class="form-control w-full max-w-xl">
                      <div class="label">
                        <span class="label-text">Number of courts available</span>
                      </div>
                      <input type="number" placeholder="" class="input input-bordered w-full" bind:value={$form.courts} />
                      <div class="label">
                        {#if ($form.errors.courts)}
                        <span class="label-text-alt text-error">{$form.errors.courts}</span>{/if}
                        <span class="label-text-alt">(max. number of courts used is determined by available number of players for scheduling a round)</span>
                      </div>
                    </label>

                    <label class="form-control w-full max-w-xl">
                      <div class="label">
                        <span class="label-text">Score to reach in a match</span>
                      </div>
                      <input type="number" min="5" max="50" placeholder="Input a number" class="input input-bordered w-full" bind:value={$form.scoreLimit} />
                      <div class="label">
                        {#if ($form.errors.scoreLimit)}
                        <span class="label-text-alt text-error">{$form.errors.scoreLimit}</span>{/if}
                        <span class="label-text-alt text-warning">This cannot be changed later!</span>
                      </div>
                    </label>

                    <label class="form-control w-full max-w-xl">
                      <div class="label">
                        <span class="label-text">Number of player groups</span>
                      </div>

                      <input type="number" disabled class="input input-bordered input-primary w-full" bind:value={$form.sessions} />
                        <FutureFeature stage="plan" withIcon>
                        <!-- <span slot="title">Two-Factor authentication</span> -->
                            <span slot="description">Multiple player groups will be available in future versions of premium package.</span>
                        </FutureFeature>

                      <div class="label">
                        {#if ($form.errors.sessions)}
                        <span class="label-text-alt text-error">{$form.errors.sessions}</span>{/if}
                      </div>
                    </label>


                </svelte:fragment>
                <!-- End of form fields -->

                <!-- Start buttons and their action bindings -->
                <!-- <svelte:fragment slot="actions">
                    {#if $form.recentlySuccessful }
                    <ActionMessage style="success">
                            Profile updated
                    </ActionMessage>
                    {/if}
                    <button class="btn btn-primary text-white" on:click|preventDefault={sendForm} disabled={$form.processing}>Create event
                    </button>
                </svelte:fragment> -->
                <!-- End action buttons -->

            </FormSection>
            <!-- End of formsection -->

        <div class="divider"></div>
            <AddPlayersForm bind:playerNames={playerNamesList} bind:playerCount={$form.playerCount} bind:ordered={$form.isOrdered}/>


        <div class="divider"></div>
            <FormSection>
                <svelte:fragment slot="actions">
                {#if $form.recentlySuccessful }
                <ActionMessage style="success">
                          Changes are saved
                </ActionMessage>
                {/if}
                <Link class="btn btn-info btn-outline mx-4" href={route('app.home')}>Close & return</Link>

                <button class="btn btn-primary" on:click|preventDefault={sendForm} >Save and create</button>
            </svelte:fragment>
            </FormSection>


        </div>
    </div>
</div>
