<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';
  export const layout = [SiteLayout];

</script>

<script>
    import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    import dayjs from 'dayjs';
    import relativeTime from 'dayjs/plugin/relativeTime';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    import isToday from 'dayjs/plugin/isToday'
    dayjs.extend(customParseFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(isToday);

    import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
    import DialogModal from 'cmpt/app_ui/DialogModal.svelte';
    import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';
    import FutureFeature from 'cmpt/app_ui/FutureFeature.svelte';
    import FormSection from 'cmpt/app_ui/FormSection.svelte';
    import AddPlayersForm from './Partials/AddPlayersForm.svelte';
    
    
    export let pageName = $page.props.pageTitle ?? 'Start practice';
    export let appName = $page.props.appName;
    export let playerNamesList = [];
    export let formDefaults = $page.props.fillData;
    export let startsToday = false;
    export let timeLabel = '';
    export let timeLabel2 = '';
    
    let nextId = 1; //this will change

    const form = useForm({
        name: formDefaults.name ?? 'Practice session',
        description: '',
        date_starts: dayjs(formDefaults.date_starts).format('DD.MM.YY') ?? '',
        date_ends: '',
        courts: formDefaults.courts ?? 1,
        sessions: 1,
        playerNames: [],
        playerCount: (formDefaults.courts * 6) || 7,
        isOrdered: false,
        scoreLimit: formDefaults.scoreLimit || 21,
        type: formDefaults.type || "P" // sets event type to practice if no other value is recieved

    });

    const sendForm = () => {
        // console.log("Form send...")
        // console.log(playerNamesList);

        const names = playerNamesList.map(row => row.name).filter(name => name && name.trim()!== "");

        $form.playerNames = names;

        console.log($form.playerNames);

        $form.post(route('event.quickstart'), {
            errorBag: 'quickstart',
            preserveScroll: true,
            onSuccess: () => { console.log("event created successfully");},
            onError: () => {
                console.log("errors on event quickstart:")
                console.log($form.errors)
            },
        });
    };


    // ===============================================
    onMount(() => {
        console.log("Create event view loaded:")
        console.log($form);

        console.log($form.data());
        
        //initialize player names (for dev purpose)
        const defaultNamesList = [
    "Alex", "Benjamin", "Charlotte", "Daniel", "Emily", "Frank", "Grace", "Henry", "Isabella",
    "Jacob", "Kevin", "Lily", "Mia", "Nathan", "Olivia",
    "Parker", "Quinn", "Robert", "Sophia", "Thomas", "Ursula", "Vincent", "William",
    "Xavier", "Yasmin", "Zoe"];
        if(playerNamesList.length == 0) {
            let limit = $form.playerCount;
            for(let i=1; i<=limit; i++) {
              playerNamesList.push({id: nextId++, name: '', uniq: false, empty: true});

              //here it fills in default name from list. Comment out next line to leave fields empty
              playerNamesList[playerNamesList.length-1].name = defaultNamesList[i-1]
            }
        }
    });



  // ========================================================
  // Reactive statements

    $: if($form.date_starts) {
        //console.log(`date changed: ${$form.date_starts}`)
        startsToday = dayjs($form.date_starts, 'DD.MM.YY').isToday();
        if(startsToday === true) {
            timeLabel = "starts today";
        } else {
            timeLabel = "starts " + dayjs().to(dayjs($form.date_starts,'DD.MM.YY'));
        }
        
    } else {
        timeLabel = '';
    }

    
</script>

<svelte:head>
    <title>{pageName} > { appName }</title>
</svelte:head>


<div class="h-full">
    <ViewHeader>
            {pageName}
    </ViewHeader>

    <div>
        <div class="max-w-6xl mx-auto py-10 sm:px-6 lg:px-8 flex flex-col border-opacity-50">
            <!-- Start of formsection -->
            <FormSection>
                <span slot="title">
                       Practice session
                </span>
                <span slot="description">
                </span>

                <!-- Start form fields -->
                <svelte:fragment slot="formFields">
                <p>Public name of session: {$form.name}</p>
                <p>Courts: 1</p>
                <p>Date: today</p>

                <label class="form-control w-full max-w-xl">
                  <div class="label">
                    <span class="label-text">Score to reach in a match</span>
                  </div>
                  <input type="number" min="5" max="50" placeholder="Input a number" class="input input-bordered w-full" bind:value={$form.scoreLimit} />
                  <div class="label">
                    {#if ($form.errors.scoreLimit)}
                    <span class="label-text-alt text-error">{$form.errors.scoreLimit}</span>{/if}
                    <span class="label-text-alt text-warning">This cannot be changed later!</span>
                  </div>
                </label>

                </svelte:fragment>
                <!-- End of form fields -->

            </FormSection>
            <!-- End of general formsection -->


        <div class="divider"></div>
            <AddPlayersForm min="5" max="8" bind:playerNames={playerNamesList} bind:playerCount={$form.playerCount} bind:ordered={$form.isOrdered}/>


        <div class="divider"></div>
            <FormSection>
                <svelte:fragment slot="actions">
                {#if $form.recentlySuccessful }
                <ActionMessage style="success">
                          Changes are saved
                </ActionMessage>
                {/if}
                <Link class="btn btn-info btn-outline mx-4" href={route('app.home')}>Close & return</Link>

                <button class="btn btn-primary" on:click|preventDefault={sendForm} >Save and create</button>
            </svelte:fragment>
            </FormSection>


        </div>
    </div>
</div>
