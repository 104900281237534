<script>
	import { Link, page, router } from '@inertiajs/svelte';
	
	export let active;
	export let title = "";
	

  // ----------------------------------------
  // Default values for route resolution
  // "False" fallbacks are set explicitly to enable checking against "not present in current URL"
  
  export let eventHash = route().params.eventHash ?? false;
  export let sessionHash = route().params.sessionHash ?? false;

  // Set to values from $page, if they are set (effectively they should be the same)
  eventHash = $page.props.event?.hash || eventHash;
  sessionHash = $page.props.session?.hash || sessionHash;

  // ----------------------------------------
  // Reactive statements
  // $: if(true) {}

</script>





<div class="navbar bg-white border-b border-base-300 shadow-md px-4 lg:px-8">
  <div class="navbar-start">
    <div class="dropdown">
      <div tabindex="0" role="button" class="btn btn-ghost md:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16" />
        </svg>
      </div>
      <ul
        tabindex="0"
        class="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
        <li><Link href={route('event.quick.home', eventHash)}>Overview</Link></li>
        <li><Link href={route('event.quick.players', eventHash)}>Players</Link></li>
        <li><Link href={route('event.quick.scheduling', eventHash)}>Scheduling</Link></li>
        <li><Link href={route('event.quick.standings', eventHash)}>Standings</Link></li>
      </ul>
    </div>
    <!-- <Link class="link-primary font-bold">← {title}</Link> -->
  </div>
  <div class="navbar-center hidden md:flex">
    <ul class="menu menu-horizontal px-1">
		<li ><Link href={route('event.quick.home', eventHash)} class="text-secondary {(active == 'overview') && 'active'}">Overview</Link></li>
	    <li><Link href={route('event.quick.players', eventHash)} class="text-secondary {(active == 'players') && 'active'}">Players</Link></li>
	    <li><Link href={route('event.quick.scheduling', eventHash)} class="text-secondary {(active == 'scheduling') && 'active'}">Scheduling</Link></li>
      <li><Link href={route('event.quick.standings', eventHash)} class="text-secondary {(active == 'standings') && 'active'}">Standings</Link></li>
    </ul>
  </div>
  <div class="navbar-end">
    <!-- <Link class="link">Dash</Link> -->
  </div>
</div>

<style>
	
</style>
